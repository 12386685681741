import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5e84d150 = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _3807704c = () => interopDefault(import('../pages/artist/index.vue' /* webpackChunkName: "pages/artist/index" */))
const _e6d07474 = () => interopDefault(import('../pages/boost/index.vue' /* webpackChunkName: "pages/boost/index" */))
const _2105443e = () => interopDefault(import('../pages/chats/index.vue' /* webpackChunkName: "pages/chats/index" */))
const _5d813c44 = () => interopDefault(import('../pages/cosplayers/index.vue' /* webpackChunkName: "pages/cosplayers/index" */))
const _f3adff44 = () => interopDefault(import('../pages/creator-grant/index.vue' /* webpackChunkName: "pages/creator-grant/index" */))
const _2c2a4ffc = () => interopDefault(import('../pages/discover/index.vue' /* webpackChunkName: "pages/discover/index" */))
const _0463a5db = () => interopDefault(import('../pages/download/index.vue' /* webpackChunkName: "pages/download/index" */))
const _9de2a9a8 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _6a4830b0 = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _161e0764 = () => interopDefault(import('../pages/games/index.vue' /* webpackChunkName: "pages/games/index" */))
const _a402bf84 = () => interopDefault(import('../pages/languages/index.vue' /* webpackChunkName: "pages/languages/index" */))
const _a429ae70 = () => interopDefault(import('../pages/location/index.vue' /* webpackChunkName: "pages/location/index" */))
const _5e75dbe8 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _943e7346 = () => interopDefault(import('../pages/logout/index.vue' /* webpackChunkName: "pages/logout/index" */))
const _ab890f10 = () => interopDefault(import('../pages/manage/index.vue' /* webpackChunkName: "pages/manage/index" */))
const _ae9a7724 = () => interopDefault(import('../pages/marketplace/index.vue' /* webpackChunkName: "pages/marketplace/index" */))
const _0b52e269 = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _0d2313f3 = () => interopDefault(import('../pages/post/index.vue' /* webpackChunkName: "pages/post/index" */))
const _efad45a2 = () => interopDefault(import('../pages/press-kit/index.vue' /* webpackChunkName: "pages/press-kit/index" */))
const _7fddbfae = () => interopDefault(import('../pages/pricing/index.vue' /* webpackChunkName: "pages/pricing/index" */))
const _4e3ece3e = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _6e934e66 = () => interopDefault(import('../pages/scan.vue' /* webpackChunkName: "pages/scan" */))
const _22e228e4 = () => interopDefault(import('../pages/search-results/index.vue' /* webpackChunkName: "pages/search-results/index" */))
const _184183b7 = () => interopDefault(import('../pages/shared-screen/index.vue' /* webpackChunkName: "pages/shared-screen/index" */))
const _f8b8ef2a = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _7c3f5049 = () => interopDefault(import('../pages/streamers/index.vue' /* webpackChunkName: "pages/streamers/index" */))
const _ec9e46ae = () => interopDefault(import('../pages/username/index.vue' /* webpackChunkName: "pages/username/index" */))
const _c051f3e8 = () => interopDefault(import('../pages/wallet/index.vue' /* webpackChunkName: "pages/wallet/index" */))
const _ce212aa6 = () => interopDefault(import('../pages/chats/broadcast.vue' /* webpackChunkName: "pages/chats/broadcast" */))
const _ff620e74 = () => interopDefault(import('../pages/dragonpay/confirm.vue' /* webpackChunkName: "pages/dragonpay/confirm" */))
const _f0d5f33c = () => interopDefault(import('../pages/ipaymu/cancel.vue' /* webpackChunkName: "pages/ipaymu/cancel" */))
const _403eddf0 = () => interopDefault(import('../pages/ipaymu/confirm.vue' /* webpackChunkName: "pages/ipaymu/confirm" */))
const _533bd0e0 = () => interopDefault(import('../pages/login/challenge/index.vue' /* webpackChunkName: "pages/login/challenge/index" */))
const _38f5cae9 = () => interopDefault(import('../pages/login/discord/index.vue' /* webpackChunkName: "pages/login/discord/index" */))
const _dd3c75d4 = () => interopDefault(import('../pages/manage/account/index.vue' /* webpackChunkName: "pages/manage/account/index" */))
const _afc1197e = () => interopDefault(import('../pages/manage/change-password/index.vue' /* webpackChunkName: "pages/manage/change-password/index" */))
const _2ac78c42 = () => interopDefault(import('../pages/manage/donation/index.vue' /* webpackChunkName: "pages/manage/donation/index" */))
const _144bcc27 = () => interopDefault(import('../pages/manage/donation-settings/index.vue' /* webpackChunkName: "pages/manage/donation-settings/index" */))
const _6235bd9a = () => interopDefault(import('../pages/manage/edit-profile/index.vue' /* webpackChunkName: "pages/manage/edit-profile/index" */))
const _0846ebdc = () => interopDefault(import('../pages/manage/feedback/index.vue' /* webpackChunkName: "pages/manage/feedback/index" */))
const _d73ae17a = () => interopDefault(import('../pages/manage/get-stream-access/index.vue' /* webpackChunkName: "pages/manage/get-stream-access/index" */))
const _051393f8 = () => interopDefault(import('../pages/manage/languages/index.vue' /* webpackChunkName: "pages/manage/languages/index" */))
const _13b55c3a = () => interopDefault(import('../pages/manage/membership-settings/index.vue' /* webpackChunkName: "pages/manage/membership-settings/index" */))
const _465f9106 = () => interopDefault(import('../pages/manage/my-following/index.vue' /* webpackChunkName: "pages/manage/my-following/index" */))
const _ef718cce = () => interopDefault(import('../pages/manage/my-listings/index.vue' /* webpackChunkName: "pages/manage/my-listings/index" */))
const _3b1d14cc = () => interopDefault(import('../pages/manage/my-orders.vue' /* webpackChunkName: "pages/manage/my-orders" */))
const _1dfe7930 = () => interopDefault(import('../pages/manage/my-orders/downloads.vue' /* webpackChunkName: "pages/manage/my-orders/downloads" */))
const _c141ec00 = () => interopDefault(import('../pages/manage/my-orders/purchased.vue' /* webpackChunkName: "pages/manage/my-orders/purchased" */))
const _465d4632 = () => interopDefault(import('../pages/manage/my-orders/sold.vue' /* webpackChunkName: "pages/manage/my-orders/sold" */))
const _1df21d3c = () => interopDefault(import('../pages/manage/my-posts.vue' /* webpackChunkName: "pages/manage/my-posts" */))
const _2bd1c14a = () => interopDefault(import('../pages/manage/my-posts/published.vue' /* webpackChunkName: "pages/manage/my-posts/published" */))
const _8efc72cc = () => interopDefault(import('../pages/manage/my-posts/scheduled.vue' /* webpackChunkName: "pages/manage/my-posts/scheduled" */))
const _46295bc2 = () => interopDefault(import('../pages/manage/my-posts/components/ConfirmationModal.vue' /* webpackChunkName: "pages/manage/my-posts/components/ConfirmationModal" */))
const _046d544c = () => interopDefault(import('../pages/manage/my-posts/components/DeletePostModal.vue' /* webpackChunkName: "pages/manage/my-posts/components/DeletePostModal" */))
const _4cfc34a6 = () => interopDefault(import('../pages/manage/my-posts/components/ExistingCategories.vue' /* webpackChunkName: "pages/manage/my-posts/components/ExistingCategories" */))
const _e3cd2ad6 = () => interopDefault(import('../pages/manage/my-posts/components/ModalCategory.vue' /* webpackChunkName: "pages/manage/my-posts/components/ModalCategory" */))
const _3eec5056 = () => interopDefault(import('../pages/manage/my-posts/components/postCard.vue' /* webpackChunkName: "pages/manage/my-posts/components/postCard" */))
const _1e8f8d84 = () => interopDefault(import('../pages/manage/my-posts/components/postFilter.vue' /* webpackChunkName: "pages/manage/my-posts/components/postFilter" */))
const _171a3f80 = () => interopDefault(import('../pages/manage/my-posts/components/postType.vue' /* webpackChunkName: "pages/manage/my-posts/components/postType" */))
const _0972a522 = () => interopDefault(import('../pages/manage/my-subscriptions/index.vue' /* webpackChunkName: "pages/manage/my-subscriptions/index" */))
const _ce5ad6be = () => interopDefault(import('../pages/manage/my-supporters/index.vue' /* webpackChunkName: "pages/manage/my-supporters/index" */))
const _d3277502 = () => interopDefault(import('../pages/manage/my-transactions/index.vue' /* webpackChunkName: "pages/manage/my-transactions/index" */))
const _69d81f91 = () => interopDefault(import('../pages/manage/notifications/index.vue' /* webpackChunkName: "pages/manage/notifications/index" */))
const _2f9c47ac = () => interopDefault(import('../pages/manage/referral/index.vue' /* webpackChunkName: "pages/manage/referral/index" */))
const _47a8a5a0 = () => interopDefault(import('../pages/manage/settings/index.vue' /* webpackChunkName: "pages/manage/settings/index" */))
const _719b860e = () => interopDefault(import('../pages/manage/stream-settings/index.vue' /* webpackChunkName: "pages/manage/stream-settings/index" */))
const _08798608 = () => interopDefault(import('../pages/manage/verify-user/index.vue' /* webpackChunkName: "pages/manage/verify-user/index" */))
const _7afeb606 = () => interopDefault(import('../pages/manage/wallet/index.vue' /* webpackChunkName: "pages/manage/wallet/index" */))
const _753021d0 = () => interopDefault(import('../pages/manage/widgets/index.vue' /* webpackChunkName: "pages/manage/widgets/index" */))
const _35f48a7c = () => interopDefault(import('../pages/manage/withdrawal/index.vue' /* webpackChunkName: "pages/manage/withdrawal/index" */))
const _e5de4d14 = () => interopDefault(import('../pages/paypal/cancel.vue' /* webpackChunkName: "pages/paypal/cancel" */))
const _ec41bf18 = () => interopDefault(import('../pages/paypal/confirm.vue' /* webpackChunkName: "pages/paypal/confirm" */))
const _56bb990a = () => interopDefault(import('../pages/senangpay/confirm.vue' /* webpackChunkName: "pages/senangpay/confirm" */))
const _aa222638 = () => interopDefault(import('../pages/signup/interest/index.vue' /* webpackChunkName: "pages/signup/interest/index" */))
const _57a3288c = () => interopDefault(import('../pages/signup/purpose/index.vue' /* webpackChunkName: "pages/signup/purpose/index" */))
const _101202a0 = () => interopDefault(import('../pages/signup/role/index.vue' /* webpackChunkName: "pages/signup/role/index" */))
const _b6c8b0e0 = () => interopDefault(import('../pages/signup/username/index.vue' /* webpackChunkName: "pages/signup/username/index" */))
const _eed13004 = () => interopDefault(import('../pages/xendit/failed.vue' /* webpackChunkName: "pages/xendit/failed" */))
const _2c6ea692 = () => interopDefault(import('../pages/xendit/success.vue' /* webpackChunkName: "pages/xendit/success" */))
const _0afa9575 = () => interopDefault(import('../pages/manage/membership-settings/add/index.vue' /* webpackChunkName: "pages/manage/membership-settings/add/index" */))
const _65d5b22b = () => interopDefault(import('../pages/manage/my-listings/add/index.vue' /* webpackChunkName: "pages/manage/my-listings/add/index" */))
const _30562a34 = () => interopDefault(import('../pages/manage/membership-settings/edit/_id.vue' /* webpackChunkName: "pages/manage/membership-settings/edit/_id" */))
const _bba184e4 = () => interopDefault(import('../pages/manage/my-listings/edit/_slug.vue' /* webpackChunkName: "pages/manage/my-listings/edit/_slug" */))
const _3e4b880a = () => interopDefault(import('../pages/manage/my-subscriptions/_id.vue' /* webpackChunkName: "pages/manage/my-subscriptions/_id" */))
const _30ea0aed = () => interopDefault(import('../pages/manage/payment-detail/_id.vue' /* webpackChunkName: "pages/manage/payment-detail/_id" */))
const _759deb54 = () => interopDefault(import('../pages/password/reset/_id.vue' /* webpackChunkName: "pages/password/reset/_id" */))
const _719b5c8e = () => interopDefault(import('../pages/edit-post/_id.vue' /* webpackChunkName: "pages/edit-post/_id" */))
const _6824b161 = () => interopDefault(import('../pages/edit-profile/_id.vue' /* webpackChunkName: "pages/edit-profile/_id" */))
const _f894dd06 = () => interopDefault(import('../pages/edit-service/_slug.vue' /* webpackChunkName: "pages/edit-service/_slug" */))
const _6a5cc90b = () => interopDefault(import('../pages/p/_slug.vue' /* webpackChunkName: "pages/p/_slug" */))
const _539afd5e = () => interopDefault(import('../pages/post/_id/index.vue' /* webpackChunkName: "pages/post/_id/index" */))
const _67f00e2e = () => interopDefault(import('../pages/services/_slug.vue' /* webpackChunkName: "pages/services/_slug" */))
const _fd63c6da = () => interopDefault(import('../pages/u/_nickname.vue' /* webpackChunkName: "pages/u/_nickname" */))
const _7f70a649 = () => interopDefault(import('../pages/users/_id/verify/_verifyid.vue' /* webpackChunkName: "pages/users/_id/verify/_verifyid" */))
const _7d6739b3 = () => interopDefault(import('../pages/articles/_slug/_id.vue' /* webpackChunkName: "pages/articles/_slug/_id" */))
const _6178c58f = () => interopDefault(import('../pages/events/_slug/_id.vue' /* webpackChunkName: "pages/events/_slug/_id" */))
const _2fd683dc = () => interopDefault(import('../pages/press-kit/_slug/_id.vue' /* webpackChunkName: "pages/press-kit/_slug/_id" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _d45ca920 = () => interopDefault(import('../pages/_nickname/index.vue' /* webpackChunkName: "pages/_nickname/index" */))
const _fc4ed1f8 = () => interopDefault(import('../pages/_nickname/donate/index.vue' /* webpackChunkName: "pages/_nickname/donate/index" */))
const _a43f9d6a = () => interopDefault(import('../pages/_nickname/shop/index.vue' /* webpackChunkName: "pages/_nickname/shop/index" */))
const _1108ed1b = () => interopDefault(import('../pages/_nickname/feed/_id.vue' /* webpackChunkName: "pages/_nickname/feed/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/articles",
    component: _5e84d150,
    name: "articles"
  }, {
    path: "/artist",
    component: _3807704c,
    name: "artist"
  }, {
    path: "/boost",
    component: _e6d07474,
    name: "boost"
  }, {
    path: "/chats",
    component: _2105443e,
    name: "chats"
  }, {
    path: "/cosplayers",
    component: _5d813c44,
    name: "cosplayers"
  }, {
    path: "/creator-grant",
    component: _f3adff44,
    name: "creator-grant"
  }, {
    path: "/discover",
    component: _2c2a4ffc,
    name: "discover"
  }, {
    path: "/download",
    component: _0463a5db,
    name: "download"
  }, {
    path: "/events",
    component: _9de2a9a8,
    name: "events"
  }, {
    path: "/forgot-password",
    component: _6a4830b0,
    name: "forgot-password"
  }, {
    path: "/games",
    component: _161e0764,
    name: "games"
  }, {
    path: "/languages",
    component: _a402bf84,
    name: "languages"
  }, {
    path: "/location",
    component: _a429ae70,
    name: "location"
  }, {
    path: "/login",
    component: _5e75dbe8,
    name: "login"
  }, {
    path: "/logout",
    component: _943e7346,
    name: "logout"
  }, {
    path: "/manage",
    component: _ab890f10,
    name: "manage"
  }, {
    path: "/marketplace",
    component: _ae9a7724,
    name: "marketplace"
  }, {
    path: "/payment",
    component: _0b52e269,
    name: "payment"
  }, {
    path: "/post",
    component: _0d2313f3,
    name: "post"
  }, {
    path: "/press-kit",
    component: _efad45a2,
    name: "press-kit"
  }, {
    path: "/pricing",
    component: _7fddbfae,
    name: "pricing"
  }, {
    path: "/reviews",
    component: _4e3ece3e,
    name: "reviews"
  }, {
    path: "/scan",
    component: _6e934e66,
    name: "scan"
  }, {
    path: "/search-results",
    component: _22e228e4,
    name: "search-results"
  }, {
    path: "/shared-screen",
    component: _184183b7,
    name: "shared-screen"
  }, {
    path: "/signup",
    component: _f8b8ef2a,
    name: "signup"
  }, {
    path: "/streamers",
    component: _7c3f5049,
    name: "streamers"
  }, {
    path: "/username",
    component: _ec9e46ae,
    name: "username"
  }, {
    path: "/wallet",
    component: _c051f3e8,
    name: "wallet"
  }, {
    path: "/chats/broadcast",
    component: _ce212aa6,
    name: "chats-broadcast"
  }, {
    path: "/dragonpay/confirm",
    component: _ff620e74,
    name: "dragonpay-confirm"
  }, {
    path: "/ipaymu/cancel",
    component: _f0d5f33c,
    name: "ipaymu-cancel"
  }, {
    path: "/ipaymu/confirm",
    component: _403eddf0,
    name: "ipaymu-confirm"
  }, {
    path: "/login/challenge",
    component: _533bd0e0,
    name: "login-challenge"
  }, {
    path: "/login/discord",
    component: _38f5cae9,
    name: "login-discord"
  }, {
    path: "/manage/account",
    component: _dd3c75d4,
    name: "manage-account"
  }, {
    path: "/manage/change-password",
    component: _afc1197e,
    name: "manage-change-password"
  }, {
    path: "/manage/donation",
    component: _2ac78c42,
    name: "manage-donation"
  }, {
    path: "/manage/donation-settings",
    component: _144bcc27,
    name: "manage-donation-settings"
  }, {
    path: "/manage/edit-profile",
    component: _6235bd9a,
    name: "manage-edit-profile"
  }, {
    path: "/manage/feedback",
    component: _0846ebdc,
    name: "manage-feedback"
  }, {
    path: "/manage/get-stream-access",
    component: _d73ae17a,
    name: "manage-get-stream-access"
  }, {
    path: "/manage/languages",
    component: _051393f8,
    name: "manage-languages"
  }, {
    path: "/manage/membership-settings",
    component: _13b55c3a,
    name: "manage-membership-settings"
  }, {
    path: "/manage/my-following",
    component: _465f9106,
    name: "manage-my-following"
  }, {
    path: "/manage/my-listings",
    component: _ef718cce,
    name: "manage-my-listings"
  }, {
    path: "/manage/my-orders",
    component: _3b1d14cc,
    name: "manage-my-orders",
    children: [{
      path: "downloads",
      component: _1dfe7930,
      name: "manage-my-orders-downloads"
    }, {
      path: "purchased",
      component: _c141ec00,
      name: "manage-my-orders-purchased"
    }, {
      path: "sold",
      component: _465d4632,
      name: "manage-my-orders-sold"
    }]
  }, {
    path: "/manage/my-posts",
    component: _1df21d3c,
    name: "manage-my-posts",
    children: [{
      path: "published",
      component: _2bd1c14a,
      name: "manage-my-posts-published"
    }, {
      path: "scheduled",
      component: _8efc72cc,
      name: "manage-my-posts-scheduled"
    }, {
      path: "components/ConfirmationModal",
      component: _46295bc2,
      name: "manage-my-posts-components-ConfirmationModal"
    }, {
      path: "components/DeletePostModal",
      component: _046d544c,
      name: "manage-my-posts-components-DeletePostModal"
    }, {
      path: "components/ExistingCategories",
      component: _4cfc34a6,
      name: "manage-my-posts-components-ExistingCategories"
    }, {
      path: "components/ModalCategory",
      component: _e3cd2ad6,
      name: "manage-my-posts-components-ModalCategory"
    }, {
      path: "components/postCard",
      component: _3eec5056,
      name: "manage-my-posts-components-postCard"
    }, {
      path: "components/postFilter",
      component: _1e8f8d84,
      name: "manage-my-posts-components-postFilter"
    }, {
      path: "components/postType",
      component: _171a3f80,
      name: "manage-my-posts-components-postType"
    }]
  }, {
    path: "/manage/my-subscriptions",
    component: _0972a522,
    name: "manage-my-subscriptions"
  }, {
    path: "/manage/my-supporters",
    component: _ce5ad6be,
    name: "manage-my-supporters"
  }, {
    path: "/manage/my-transactions",
    component: _d3277502,
    name: "manage-my-transactions"
  }, {
    path: "/manage/notifications",
    component: _69d81f91,
    name: "manage-notifications"
  }, {
    path: "/manage/referral",
    component: _2f9c47ac,
    name: "manage-referral"
  }, {
    path: "/manage/settings",
    component: _47a8a5a0,
    name: "manage-settings"
  }, {
    path: "/manage/stream-settings",
    component: _719b860e,
    name: "manage-stream-settings"
  }, {
    path: "/manage/verify-user",
    component: _08798608,
    name: "manage-verify-user"
  }, {
    path: "/manage/wallet",
    component: _7afeb606,
    name: "manage-wallet"
  }, {
    path: "/manage/widgets",
    component: _753021d0,
    name: "manage-widgets"
  }, {
    path: "/manage/withdrawal",
    component: _35f48a7c,
    name: "manage-withdrawal"
  }, {
    path: "/paypal/cancel",
    component: _e5de4d14,
    name: "paypal-cancel"
  }, {
    path: "/paypal/confirm",
    component: _ec41bf18,
    name: "paypal-confirm"
  }, {
    path: "/senangpay/confirm",
    component: _56bb990a,
    name: "senangpay-confirm"
  }, {
    path: "/signup/interest",
    component: _aa222638,
    name: "signup-interest"
  }, {
    path: "/signup/purpose",
    component: _57a3288c,
    name: "signup-purpose"
  }, {
    path: "/signup/role",
    component: _101202a0,
    name: "signup-role"
  }, {
    path: "/signup/username",
    component: _b6c8b0e0,
    name: "signup-username"
  }, {
    path: "/xendit/failed",
    component: _eed13004,
    name: "xendit-failed"
  }, {
    path: "/xendit/success",
    component: _2c6ea692,
    name: "xendit-success"
  }, {
    path: "/manage/membership-settings/add",
    component: _0afa9575,
    name: "manage-membership-settings-add"
  }, {
    path: "/manage/my-listings/add",
    component: _65d5b22b,
    name: "manage-my-listings-add"
  }, {
    path: "/manage/membership-settings/edit/:id",
    component: _30562a34,
    name: "manage-membership-settings-edit-id"
  }, {
    path: "/manage/my-listings/edit/:slug",
    component: _bba184e4,
    name: "manage-my-listings-edit-slug"
  }, {
    path: "/manage/my-subscriptions/:id?",
    component: _3e4b880a,
    name: "manage-my-subscriptions-id"
  }, {
    path: "/manage/payment-detail/:id?",
    component: _30ea0aed,
    name: "manage-payment-detail-id"
  }, {
    path: "/password/reset/:id?",
    component: _759deb54,
    name: "password-reset-id"
  }, {
    path: "/edit-post/:id?",
    component: _719b5c8e,
    name: "edit-post-id"
  }, {
    path: "/edit-profile/:id?",
    component: _6824b161,
    name: "edit-profile-id"
  }, {
    path: "/edit-service/:slug?",
    component: _f894dd06,
    name: "edit-service-slug"
  }, {
    path: "/p/:slug?",
    component: _6a5cc90b,
    name: "p-slug"
  }, {
    path: "/post/:id",
    component: _539afd5e,
    name: "post-id"
  }, {
    path: "/services/:slug?",
    component: _67f00e2e,
    name: "services-slug"
  }, {
    path: "/u/:nickname?",
    component: _fd63c6da,
    name: "u-nickname"
  }, {
    path: "/users/:id?/verify/:verifyid?",
    component: _7f70a649,
    name: "users-id-verify-verifyid"
  }, {
    path: "/articles/:slug/:id?",
    component: _7d6739b3,
    name: "articles-slug-id"
  }, {
    path: "/events/:slug/:id?",
    component: _6178c58f,
    name: "events-slug-id"
  }, {
    path: "/press-kit/:slug?/:id",
    component: _2fd683dc,
    name: "press-kit-slug-id"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/:nickname",
    component: _d45ca920,
    name: "nickname"
  }, {
    path: "/:nickname/donate",
    component: _fc4ed1f8,
    name: "nickname-donate"
  }, {
    path: "/:nickname/shop",
    component: _a43f9d6a,
    name: "nickname-shop"
  }, {
    path: "/:nickname/feed/:id?",
    component: _1108ed1b,
    name: "nickname-feed-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
