import cloneDeep from 'lodash/cloneDeep'
import stringify from 'qs/lib/stringify'

import { showError, getUserIDFromChannel } from '~/utils'
import { addCache, getCache, deleteAllCache } from '~/utils/cache'
import AgoraService from '~/utils/agora'

export default {
  initializeChat({ commit, dispatch }) {
    console.log('initializeChat')
    // dispatch('getChatCache')
    // eslint-disable-next-line no-underscore-dangle
    const getStatus = this.$centrifuge._status
    commit('setDisconnect', getStatus === 'disconnected')
    if (getStatus === 'disconnected') {
      // Setup Token
      dispatch('setupCentrifugeToken')
      this.$centrifuge.connect()

      const getCurrentUserId = this.$auth.user.id
      let subscription = null

      // Move subscription logic to a separate method
      const setupSubscription = () => {
        if (!subscription) {
          subscription = this.$centrifuge.subscribe(`#${getCurrentUserId}`, async message => {
            const { data } = message
            // Extract message handling logic to separate methods
            dispatch('handleChatMessage', data)
            dispatch('handleOrderMessage', { data, getCurrentUserId })
            dispatch('handleCallingMessage', { data, getCurrentUserId })
            dispatch('getUnreadCount')
            dispatch('updateChatList', data)
          })
        }
        dispatch('getUnreadCount')
      }

      this.$centrifuge.on('connect', () => {
        commit('setDisconnect', false)
        setupSubscription()
      })

      this.$centrifuge.on('disconnect', () => {
        if (subscription) {
          subscription.unsubscribe()
        }
        commit('setDisconnect', true)
      })

      dispatch('fetchActiveOrders', getCurrentUserId)
    }
  },

  // Helper Code
  setupCentrifugeToken() {
    let token = this.$auth.strategy.token.get() || ''
    if (token.includes('Bearer')) {
      // eslint-disable-next-line prefer-destructuring
      token = token.split('Bearer ')[1]
    }
    this.$centrifuge.setToken(token)
  },

  handleChatMessage({ commit, state }, payload) {
    if (state.activeChat && state.activeChat.channelID === payload.channelID) {
      commit('addActiveChatList', payload)
    }
  },

  handleOrderMessage({ dispatch, state }, { data, getCurrentUserId }) {
    console.log('handleOrderMessage', data)
    if (
      data.content.startsWith('/orders')
      && ((state.activeChat && state.activeChat.partnerUser && state.activeChat.partnerUser.id === data.userID) || data.userID === getCurrentUserId)
    ) {
      const status = data.content.split(' ')[2]
      if (['accepted', 'completed', 'cancelled'].includes(status)) {
        dispatch('getActiveOrder')
      }
    }
  },

  async handleCallingMessage({ dispatch }, payload) {
    if (payload.data.content.startsWith('/calling')) {
      const statusData = payload.data.content.split(' ')[2]
      if (statusData === 'calling') {
        dispatch('handleIncomingCall', { ...payload })
      } else if (statusData === 'end') {
        dispatch('handleEndCall')
      }
    }
  },

  async handleIncomingCall({ commit }, { data, getCurrentUserId }) {
    console.log('handleIncomingCall')
    const getOrderId = data.content.split(' ')[1]
    const { data: order } = await this.$apiV2.get(`/payment-order/orders/${getOrderId}`)

    const user = order.order_snapshot.buyer_user.nickname === getCurrentUserId ? order.order_snapshot.buyer_user : order.order_snapshot.seller_user
    const callData = {
      ...data,
      user,
      order,
    }
    commit('setCall', callData)
    if (data.userID !== getCurrentUserId) {
      commit('setIncomingCallVisible', true)
    }
  },

  handleEndCall(commit) {
    commit('setCallStarted', false)
    commit('setCall', null)
    commit('setIncomingCallVisible', false)
    const agoraService = new AgoraService()
    agoraService.leave()
  },

  // eslint-disable-next-line no-unused-vars
  async setChatListAndCheck({ commit, dispatch, state }, { value, page, perPage }) {
    const originalData = value

    const updatedChatList = [...state.chatList, ...value]
    commit('setChatList', updatedChatList)
    commit('setCurrentPage', page)

    if (typeof window !== 'undefined' && 'caches' in window) {
      const getCurrentUserId = this.$auth.user.id
      if (getCurrentUserId) {
        await addCache('chats', `/${getCurrentUserId}.json`, originalData)
      }
    }

    const shouldLoadMoreData = state.chatList.length < 20 && !state.endChatPage
    if (shouldLoadMoreData) {
      await dispatch('getChatList', { page: page + 1, perPage })
    }
  },

  async getChatList({ state, dispatch, commit }, { page = 1, perPage = 20 } = {}) {
    console.log('getChatList', state.isFetchingChatList, state.endChatPage)
    // Check if we've reached the end or if a request is already in progress
    if (state.isFetchingChatList || state.endChatPage) {
      return // Exit the function if we've reached the end or a request is in progress
    }

    commit('setIsFetchingChatList', true)

    try {
      const {
        data: { data },
      } = await this.$axios.get('chats/subscriptions-v2', {
        params: { page, perPage },
      })

      const isEndOfData = data.length < perPage || data.length === 0
      if (isEndOfData) {
        console.log('Reached end of chat list')
        commit('setEndChatPage', true)
      }
      await dispatch('setChatListAndCheck', { value: data, page, perPage })
    } catch (error) {
      console.error('Error fetching chat list:', error)
    } finally {
      commit('setIsFetchingChatList', false)
    }
  },

  async getUnreadCount({ commit }) {
    try {
      const {
        data: {
          data: {
            unreadEventCount: count,
          },
        },
      } = await this.$axios.get('chats/subscriptions-v2/count-event')
      commit('setUnreadCount', count)
    } catch (error) {
      // console.error('Error fetching unread count:', error)
    }
  },
  // Old Code

  async fetchActiveOrders({ commit }, getCurrentUserId) {
    try {
      const { data: { data } } = await this.$axios.get('orders/me', {
        params: {
          isCalling: true,
          orderBy: 'updatedAt desc',
        },
      })

      if (data.length > 0) {
        const order = data[0]
        const user = order.userID === getCurrentUserId ? order.coachData : order.userData
        const callData = { ...order, user }
        commit('setCall', callData)
      }
    } catch (error) {
      // console.error('Error fetching active orders:', error)
    }
  },

  async checkBlacklist({ commit }, { peerId, mineId }) {
    const {
      data: { data: blacklist },
    } = await this.$axios.get(`users/blacklists/check?userID=${peerId}&checkingUserID=${mineId}`)
    commit('setBlacklist', blacklist.isBlacklist)
  },
  async getChatCache({ commit }) {
    if (typeof window !== 'undefined' && 'caches' in window) {
      const getCurrentUserId = this.$auth.user.id
      const chats = await getCache('chats', `/${getCurrentUserId}.json`)
      if (chats) {
        console.log('getChatCache', chats)
        commit('setChatList', chats)
      }
    }
  },

  async updateChatList({ state, commit }, message) {
    console.log('updateChatList message', message)

    const chatIndex = state.chatList.findIndex(x => x.channelID === message.channelID)
    console.log('updateChatList indexOf', chatIndex)

    if (chatIndex !== -1) {
      // Chat exists, update it
      const updatedChat = { ...state.chatList[chatIndex] }
      updatedChat.lastMessage = message

      if ((state.activeChat && state.activeChat.channelID !== message.channelID) || !state.activeChat) {
        updatedChat.unreadEventCount += 1
      }

      // Use a mutation to update the specific chat
      commit('updateChatInList', { index: chatIndex, chat: updatedChat })

      if (state.activeChat && state.activeChat.channelID === message.channelID) {
        try {
          await this.$centrifuge.rpc({
            method: 'updateReadEvent',
            data: {
              eventID: message.id,
              channelID: message.channelID,
              readEventSeq: message.seq,
            },
          })
        } catch (error) {
          console.error('Error updating read event:', error)
        }
      }
    } else {
      // Chat doesn't exist, fetch and add it
      console.log('updateChatList else')
      try {
        const { data: { data: chats } } = await this.$axios.get('chats/subscriptions-v2', {
          params: { channelID: message.channelID },
        })
        console.log('updateChatList params', chats)

        if (chats && chats.length > 0) {
          // Use a mutation to add new chats to the list
          commit('addChatsToList', chats)
        }
      } catch (error) {
        console.error('Error fetching new chat:', error)
      }
    }
  },
  // async updateChatList({ dispatch, state }, message) {
  //   console.log('updateChatList message', message)
  //   let chatList = [...state.chatList]
  //   if (chatList && chatList.length > 0) {
  //     const indexOf = chatList.findIndex(x => x.channelID === message.channelID)
  //     console.log('updateChatList indexOf', indexOf)
  //     if (indexOf !== -1) {
  //       chatList[indexOf].lastMessage = message
  //       if (
  //         (state.activeChat && state.activeChat.channelID !== message.channelID)
  //         || !state.activeChat
  //       ) {
  //         chatList[indexOf].unreadEventCount += 1
  //       }
  //       if (state.activeChat && state.activeChat.channelID === message.channelID) {
  //         try {
  //           await this.$centrifuge.rpc({
  //             method: 'updateReadEvent',
  //             data: {
  //               eventID: message.id,
  //               channelID: message.channelID,
  //               readEventSeq: message.seq,
  //             },
  //           })
  //         } catch (error) { }
  //       }
  //     } else {
  //       console.log('updateChatList else')
  //       const {
  //         data: { data: chats },
  //       } = await this.$axios.get('chats/subscriptions-v2', {
  //         params: {
  //           channelID: message.channelID,
  //         },
  //       })
  //       console.log('updateChatList params', chats)
  //       if (chats && chats.length > 0) {
  //         chatList = chats.concat(chatList)
  //         console.log('updateChatList if chats', chatList)
  //       }
  //     }
  //     console.log('setChatList', chatList)
  //     // commit('setChatList', chatList)
  //     dispatch('sortAndOrderChatList', chatList)
  //   }
  // },

  async getEmojiData({ commit, state }) {
    if (state.emojiSet.length === 0) {
      const {
        data: { data: emoji },
      } = await this.$axios.get('chats/emoji-sets')
      commit('setEmojiSet', emoji)
    }
  },
  async getCacheActiveChatList({ commit, state, dispatch }) {
    commit('clearActiveChatList')
    if (typeof window !== 'undefined' && 'caches' in window) {
      const getCurrentUserId = this.$auth.user.id
      const otherUserId = getUserIDFromChannel(state.activeChat?.channelName, getCurrentUserId)
      const getActiveChatList = await getCache('chats', `${getCurrentUserId}/chat/${otherUserId}.json`)
      if (getActiveChatList) {
        commit('setActiveChatList', getActiveChatList)
      }
    }
    dispatch('getActiveChatList')
  },
  async getActiveChatList({ commit, state }, loadMore = false) {
    if (state.loading) return
    commit('setLoading', true)

    try {
      const chats = cloneDeep(state.activeChatList)
      const lastChats = chats[chats.length - 1]

      let payload = {
        channelID: state.activeChat.channelID,
        limit: 10,
      }
      if (loadMore && lastChats && lastChats.seq) {
        payload = {
          ...payload,
          direction: 'up',
          lastSeq: lastChats.seq,
        }
      }

      let { data } = await this.$centrifuge.rpc({
        method: 'getHistory',
        data: payload,
      })

      if (state.activeChatList && state.activeChatList.length > 0) {
        data.forEach(element => {
          const findExists = chats.findIndex(x => x.id === element.id)
          if (findExists === -1) {
            chats.push(element)
          }
        })
        data = chats
      }

      commit('setActiveChatList', data)
    } catch (error) {
      showError(error)
    }
    commit('setLoading', false)
  },
  async removeChats({ commit }) {
    if (typeof window !== 'undefined' && 'caches' in window) {
      await deleteAllCache()
    }
    commit('removeChat')
    commit('clearActiveChatList')
  },
  async createChannel({ commit, dispatch, state }, userIds) {
    console.log('createChannel', userIds)
    // eslint-disable-next-line no-underscore-dangle
    const getStatus = this.$centrifuge._status
    if (getStatus === 'disconnected') {
      console.log('createChannel getStatus', getStatus)
      dispatch('initializeChat')
    }
    try {
      userIds.sort()
      const channelName = `#${userIds.join(',')}`
      const { data } = await this.$centrifuge.rpc({
        method: 'createChannel',
        data: {
          channelName,
        },
      })
      console.log('createChannel centrifuge data', data)

      const currentUserId = this.$auth.user.id
      const chat = {
        channelID: data.id,
        channelName: data.name,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
        id: data.id,
        userID: currentUserId,
      }
      const userId = getUserIDFromChannel(channelName, currentUserId)
      console.log('createChannel userId', userId)
      const {
        data: { data: user },
      } = await this.$axios.get(`/users/${userId}`)
      chat.partnerUser = user
      console.log('createChannel chat with user data', chat)

      commit('clearActiveChatList')
      commit('setChatList', [...state.chatList, chat])
      commit('setActiveChat', chat)
    } catch (error) {
      showError(error)
    }
  },
  async getActiveOrder({ commit, state }) {
    try {
      const userID = getUserIDFromChannel(state.activeChat?.channelName, this.$auth.user.id)
      const {
        data: { data },
      } = await this.$axios.get(
        `orders/me?${stringify(
          {
            statuses: ['accepted'],
            userID,
          },
          { indices: false },
        )}`,
      )
      if (data && data.length > 0) {
        commit('setActiveOrder', data[0])
      } else {
        commit('setActiveOrder', null)
      }
    } catch {
      // silent error
    }
  },
}
