import get from 'lodash/get'
import Endpoint from '~/repository/endpoint'
import CryptoJS from 'crypto-js'
import stringify from 'qs/lib/stringify'
import {
  PAYMENT_METHOD_TYPES, showError, formatMoney, formatLocale, formatLocaleCurrency,
} from '~/utils'
import stripeCountry from '~/mixins/stripe-country'
import { mapActions } from 'vuex'

export default {
  mixins: [stripeCountry],
  data() {
    return {
      stripe: '',
      stripeElement: null,
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.stripe = Stripe(this.getStripeKey())
  },
  methods: {
    ...mapActions({
      updateDefaultPaymentMethod: 'payment/updateDefaultPaymentMethod',
    }),
    // temporary, currently only available for service creation
    async createOrderPayment(data, paymentMethodID) {
      const payload = await this.checkPayload(data, paymentMethodID)
      return this.$apiV2.post('payment/transactions',
        payload).catch(error => {
        this.setLoading(false)
        showError(error)
      })
    },
    // need 2 data payload, payment method id
    async createPayment(data, paymentMethodID) {
      const payload = await this.checkPayload(data, paymentMethodID)
      // Access the authentication token from Nuxt Auth
      const authToken = await this.$auth.strategy.token.get()
      return this.$axios.post(Endpoint.paymentV2,
        payload,
        {
          baseURL: Endpoint.apiUrl,
          headers: authToken ? { Authorization: authToken } : {},
        }).catch(error => {
        this.setLoading(false)
        showError(error)
      })
    },
    encryptTransaction(data) {
      const key = CryptoJS.enc.Utf8.parse(process.env.NUXT_ENV_CRYPTO_KEY)
      const iv = CryptoJS.enc.Utf8.parse(process.env.NUXT_ENV_CRYPTO_IV)
      const secret = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
        iv,
        mode: CryptoJS.mode.CBC,
        keySize: 128 / 8,
        padding: CryptoJS.pad.Pkcs7,
      }).toString()
      // save to cookies and set expired date
      // transactionID is for order v1
      if (data && data.transactionID) {
        this.$cookies.set(data.transactionID, secret, {
          maxAge: 10 * 60,
        })
      }
      if (data && data.tx_human_id) {
        this.$cookies.set(data.tx_human_id, secret, {
          maxAge: 10 * 60,
        })
      }
      if (data && data.sub_human_id) {
        this.$cookies.set(data.sub_human_id, secret, {
          maxAge: 10 * 60,
        })
      }
    },
    async createRecurringPayment(data, paymentMethodID) {
      const payload = await this.checkPayload(data, paymentMethodID)
      // Access the authentication token from Nuxt Auth
      const authToken = await this.$auth.strategy.token.get()
      return this.$axios.post(Endpoint.subscriptionPaymentV2,
        payload,
        {
          baseURL: Endpoint.apiUrl,
          headers: authToken ? { Authorization: authToken } : {},
        }).catch(error => {
        this.setLoading(false)
        showError(error)
      })
    },
    async getUserTransaction(params) {
      // Access the authentication token from Nuxt Auth
      const authToken = await this.$auth.strategy.token.get()
      return this.$axios.get(`${Endpoint.paymentV2}?${stringify(params, {
        indices: false,
        encode: false,
        skipNulls: true,
      })}`,
      {
        baseURL: Endpoint.apiUrl,
        headers: authToken ? { Authorization: authToken } : {},
      })
    },
    async checkPayload(data, paymentMethodID) {
      let payload = { ...data }
      const paymentSrc = data.usecase_data.payment_src
      const paymentType = paymentSrc ? paymentSrc.toLowerCase() : null
      if (!paymentType) {
        payload = {
          ...payload,
          usecase_data: {
            ...payload.usecase_data,
            payment_src: 'INTERNAL',
          },
        }
      }
      if ((paymentType === PAYMENT_METHOD_TYPES.CARD || paymentType === PAYMENT_METHOD_TYPES.STRIPE) && payload.usecase !== 'RETRY_PAYMENT') {
        payload = {
          ...payload,
          usecase_data: {
            ...payload.usecase_data,
            payment_src: 'STRIPE',
            payment_src_data: { payment_method_id: paymentMethodID },
          },
        }
      }

      if (paymentType === PAYMENT_METHOD_TYPES.STRIPE_GOOGLE_PLAY || paymentType === PAYMENT_METHOD_TYPES.STRIPE_APPLEPAY) {
        payload = {
          ...payload,
          usecase_data: {
            ...payload.usecase_data,
            payment_src_data: {},
          },
        }
      }

      if (paymentType === PAYMENT_METHOD_TYPES.GRABPAY) {
        // eslint-disable-next-line no-undef
        const { paymentMethod, error } = await this.stripe.createPaymentMethod({
          type: PAYMENT_METHOD_TYPES.GRABPAY,
        })
        if (error) {
          this.errorMessage = get(error, 'message') || this.$t('failedMakePayment')
          return null
        }
        payload = {
          ...payload,
          usecase_data: {
            ...payload.usecase_data,
            payment_src: 'STRIPE_GRABPAY',
            payment_src_data: { payment_method_id: paymentMethod.id },
          },
        }
      }

      if (paymentType === PAYMENT_METHOD_TYPES.EARNINGS) {
        payload = {
          ...payload,
          usecase_data: {
            ...payload.usecase_data,
            payment_src: 'WALLET_GANK_EARNING',
          },
        }
      }

      if (paymentType === PAYMENT_METHOD_TYPES.GOLD) {
        payload = {
          ...payload,
          usecase_data: {
            ...payload.usecase_data,
            payment_src: 'WALLET_GANK_GOLD',
            currency_as_charged: 'GANK_GOLD',
          },
        }
      }

      if (paymentType === PAYMENT_METHOD_TYPES.STRIPE_WECHAT_PAY) {
        payload = {
          ...payload,
          usecase_data: {
            ...payload.usecase_data,
            currency_as_charged: this.countryUser === 'SG' ? 'SGD' : 'CNY',
          },
        }
      }

      if (paymentType && (paymentType.startsWith('xendit') || paymentType.startsWith('siampay') || paymentType.startsWith('pepay'))) {
        // get payment source and method by splitting, can be used for other future paymentType e.g siampay
        const [SplitSource, ...SplitMethod] = paymentType.toUpperCase().split('_')
        let PaymentMethod = SplitMethod.join('_')
        if (PaymentMethod === 'CONVENIENCE_STORE') {
          PaymentMethod = 'CONVENIENCE_STORE_FAMI'
        }
        if (PaymentMethod === 'ATM') {
          PaymentMethod = 'ATM_CTCB'
        }
        payload = {
          ...payload,
          usecase_data: {
            ...payload.usecase_data,
            payment_src: SplitSource,
            payment_src_data: { payment_method: PaymentMethod },
          },
        }
      }

      if (paymentType && paymentType.startsWith('zalopay')) {
        payload.usecase_data.payment_src = 'ZALOPAY'
        switch (paymentType) {
          case PAYMENT_METHOD_TYPES.ZALOPAY_LOCALBANK:
            payload = {
              ...payload,
              usecase_data: {
                ...payload.usecase_data,
                payment_src_data: { payment_method: 'INTERNET_BANKING' },
              },
            }
            break
          case PAYMENT_METHOD_TYPES.ZALOPAY_VIETQR:
            payload = {
              ...payload,
              usecase_data: {
                ...payload.usecase_data,
                payment_src_data: { payment_method: 'VIET_QR' },
              },
            }
            break
          default:
            payload = {
              ...payload,
              usecase_data: {
                ...payload.usecase_data,
                payment_src_data: { payment_method: 'EWALLET' },
              },
            }
        }
      }
      if ((paymentType === PAYMENT_METHOD_TYPES.CARD || paymentType === PAYMENT_METHOD_TYPES.STRIPE) && this.$auth.loggedIn) {
        this.updateDefaultPaymentMethod({
          paymentMethodId: paymentMethodID,
          type: PAYMENT_METHOD_TYPES.CARD,
        })
        localStorage.setItem('defaultPaymentMethodType', PAYMENT_METHOD_TYPES.CARD)
      }
      if (paymentType !== PAYMENT_METHOD_TYPES.CARD && paymentType !== PAYMENT_METHOD_TYPES.STRIPE) {
        const type = paymentType === PAYMENT_METHOD_TYPES.GOLD ? PAYMENT_METHOD_TYPES.EARNINGS : paymentType
        this.$store.commit('payment/setDefaultPaymentMethod', {
          paymentMethodId: null,
          type,
        })
        localStorage.setItem('defaultPaymentMethodType', type)
      }
      return payload
    },

    async startPayment(data) {
      const paymentData = { ...data }

      paymentData.paymentMethod = this.paymentType // TODO: bind paymentMethod

      // cater order v1 using transactionID and v2 using tx_human_id
      const transactionID = paymentData.transactionID ? paymentData.transactionID : (paymentData.tx_human_id || paymentData.sub_human_id)
      this.$router.push(`/manage/payment-detail/${transactionID}`)
      await this.getTransactionDetail(transactionID)
      if (paymentData.status === 'succeeded' && paymentData.paymentMethod === 'stripe') {
        this.$store.commit('payment/setTransactionToCheck', this.transaction)
      }
      if (paymentData?.status !== 'succeeded') {
        await this.proceedPayment(paymentData)
      }
    },
    async getTransactionDetail(transactionID) {
      try {
        const authToken = await this.$auth.strategy.token.get()
        // check transaction id to differentiate recurring and one time transaction
        const url = ((transactionID.startsWith('UT') || transactionID.startsWith('UM')) && !transactionID.startsWith('UTO') && !transactionID.startsWith('UMO'))
          ? `${Endpoint.detailRecurringV2}?sub_human_id=${transactionID}`
          : `${Endpoint.detailTransactionV2}?human_id=${transactionID}`
        const { data } = await this.$axios.get(url,
          {
            baseURL: Endpoint.apiUrl,
            headers: authToken ? { Authorization: authToken } : {},
          })
        this.transaction = data
        // handle if recuring details return null
        if (!data) {
          const res = await this.$axios.get(`${Endpoint.detailTransactionV2}?human_id=${transactionID}`,
            {
              baseURL: Endpoint.apiUrl,
              headers: authToken ? { Authorization: authToken } : {},
            })
          this.transaction = res.data
        }
      } catch (error) {
        showError(error, 'Get user transactions failed!')
      }
    },
    async getCheckoutSummary() {
      const payload = {
        ...this.payload,
        usecase_data: {
          ...this.payload.usecase_data,
          payment_dest_data: null,
          payment_src: this.paymentType ? this.paymentType.toUpperCase() : 'INTERNAL',
          payment_src_data: null,
          price_unit_currency: 'USD',
          currency_as_charged: this.userCurrency.toUpperCase(),
        },
        paymentMethodId: this.paymentMethodId,
      }

      try {
        const isRecurring = payload.usecase.startsWith('USER_') && ['stripe', 'paypal', 'card'].includes(this.paymentType) ? 'recurring' : null

        await this.getPaymentSnapshot(payload, payload.paymentMethodId, isRecurring)

        return undefined
      } catch (error) {
        this.setLoading(false)
        this.$sentry.captureException(error)
        return undefined
      }
    },
    getBoostSummary() {
      if (this.paymentType !== 'paypal') {
        this.totalDonate = formatMoney(
          this.tierPrice,
          formatLocale(this.countryUser),
          this.userCurrency,
        )
        this.totalAmount = this.tierPrice
        this.currency = this.userCurrency
      } else {
        this.totalDonate = null
        this.currency = 'usd'
      }
    },
    generateSecret() {
      // get transaction secret data from api v2
      if (!this.secret && (this.transaction.payment_status === 'CREATED' || !this.transaction.active) && (this.transaction.payment_id || this.transaction.payment_url)) {
        this.secret = {
          data: {
            payment_provider: this.transaction.payment_src ? this.transaction.payment_src.toLowerCase() : null,
            payment_provider_data: {
              client_secret: this.transaction.stripe_payment_secret,
              payment_url: this.transaction.payment_url,
              payment_intent_status: this.transaction.stripe_payment_status,
              payment_method: this.transaction.payment_method,
            },
          },
          // to differentiate between cookie secret and api response secret
          response: true,
        }
      }
    },
    async proceedPayment(paymentData) {
      try {
        this.setLoading(true)

        let data
        if (this.secret && this.secret.response) {
          data = this.secret
        } else {
          data = paymentData
        }
        const url = new URL(window.location.origin + this.$route.fullPath)
        localStorage.setItem('tempPath', url.pathname)
        const paymentMethod = data?.data?.payment_provider || data.paymentMethod
        const paymentMethodType = data?.data?.payment_provider_data?.payment_method || data.paymentMethodType
        const redirectUrl = data?.data?.payment_provider_data.payment_url || data.redirectURL
        const transactionStatus = data?.data?.payment_provider_data.payment_intent_status || data.status
        const clientSecret = data?.data?.payment_provider_data.client_secret || data.clientSecret

        if (paymentMethod === PAYMENT_METHOD_TYPES.STRIPE_WECHAT_PAY) {
          // eslint-disable-next-line no-undef
          this.stripe = Stripe(this.$config.stripePublishableKey)
          const { error: stripeError } = await this.stripe.confirmWechatPayPayment(
            clientSecret,
            {
              payment_method_options: {
                wechat_pay: {
                  client: 'web',
                },
              },
            },
          )
          this.setLoading(false)

          if (stripeError) {
            // this.setErrorMessage(stripeError, stripeError.message)
            return
          }
        } else if (paymentMethod?.startsWith('pepay')) {
          this.processPepayPayment(clientSecret, paymentMethodType)
          this.$store.commit('payment/setTransactionToCheck', this.transaction)
        } else if (
          paymentMethod === PAYMENT_METHOD_TYPES.ELEMENTPAY) {
          await this.$store.commit('payment/setTransactionToCheck', this.transaction)
          this.handleElementPay(data)
        } else if (
          paymentMethod === PAYMENT_METHOD_TYPES.PAYPAL
          || paymentMethod === PAYMENT_METHOD_TYPES.DRAGONPAY_GCASH
          || paymentMethod === PAYMENT_METHOD_TYPES.DRAGONPAY_PAYMAYA
          || paymentMethod === PAYMENT_METHOD_TYPES.SENANGPAY
          || paymentMethod === PAYMENT_METHOD_TYPES.IPAYMU
          || paymentMethod?.startsWith('xendit')
          || paymentMethod?.startsWith('siampay')
          || paymentMethod?.startsWith('zalopay')) {
          this.setLoading(false)
          const win = window.open(redirectUrl)
          localStorage.setItem('popup', true)
          if (!win) {
            localStorage.setItem('popup', false)
            window.location.assign(redirectUrl)
          }
          this.$store.commit('payment/setTransactionToCheck', this.transaction)
        }
        if (transactionStatus === 'succeeded') {
          this.$store.commit('payment/setTransactionToCheck', this.transaction)
        } else if ((transactionStatus === 'requires_action' || transactionStatus === 'requires_payment_method' || transactionStatus === 'requires_confirmation')
          && (paymentMethod === PAYMENT_METHOD_TYPES.STRIPE || paymentMethod === PAYMENT_METHOD_TYPES.CARD)) { // Confirm card payment if returned status is "requires_action"
          this.$store.commit('payment/setTransactionToCheck', this.transaction)
          await this.handleCardConfirm(clientSecret)
        } else if (
          transactionStatus !== 'succeeded'
          && (paymentMethod === PAYMENT_METHOD_TYPES.GRABPAY || paymentMethod === 'stripe_grabpay')
        ) {
          this.$store.commit('payment/setTransactionToCheck', this.transaction)
          await this.handleGrabPayConfirm(clientSecret)
        } else if (
          transactionStatus === 'requires_confirmation'
          && paymentMethod === PAYMENT_METHOD_TYPES.STRIPE_PAYNOW
        ) {
          this.$store.commit('payment/setTransactionToCheck', this.transaction)
          await this.handlePaynowConfirm(clientSecret)
        }
        this.setLoading(false)
      } catch (error) {
        // hide button on failed transaction
        this.setLoading(false)
        showError(error)
      }
    },
    handleCardConfirm(clientSecret) {
      return new Promise((resolve, reject) => {
        this.stripe.confirmCardPayment(clientSecret).then(result => {
          if (result.paymentIntent) {
            this.$store.commit('payment/setTransactionToCheck', this.transaction)
          }
          if (result.error) {
            // add retry payment if user cancel transaction
            if (result.error?.code === 'payment_intent_authentication_failure' && this.$auth.loggedIn) {
              // need adjust for guest
              this.onRetryPayment()
            } else {
              reject(Error(result.error.message))
            }
          }
          resolve(result)
        }).catch(error => {
          this.$store.commit('payment/setTransactionToCheck', null)
          reject(error)
        })
      })
    },
    handleGrabPayConfirm(clientSecret) {
      this.stripe.confirmGrabPayPayment(clientSecret, {
        // Return URL where the customer should be redirected after
        // the authorization.
        // eslint-disable-next-line no-restricted-globals
        return_url: `${window.location.origin + this.$route.fullPath}`,
      }).then(result => {
        if (result.paymentIntent) {
          this.$store.commit('payment/setTransactionToCheck', this.transaction)
        }
        if (result.error) {
          showError(result.error.message)
        }
      }).catch(error => {
        this.$store.commit('payment/setTransactionToCheck', null)
        showError(error)
      })
    },
    handlePaynowConfirm(clientSecret) {
      this.stripe.confirmPayNowPayment(clientSecret, {}).then(result => {
        if (result.paymentIntent && result.paymentIntent.status === 'requires_action') {
          this.$store.commit('payment/setTransactionToCheck', null)
        } else if (result.paymentIntent) {
          this.$store.commit('payment/setTransactionToCheck', this.transaction)
        }

        if (result.error) {
          this.$store.commit('payment/setTransactionToCheck', null)
          showError(result.error.message)
        }
      }).catch(error => {
        this.$store.commit('payment/setTransactionToCheck', null)
        showError(error)
      })
    },
    processPepayPayment(clientSecret, paymentMethod) {
      const formattedSecret = clientSecret.replace(/\\u0026/g, '&')
      const params = new URLSearchParams(formattedSecret)
      const prodId = this.getPepayProdId(paymentMethod)
      let pepayPaymentData = {
        SHOP_ID: null,
        ORDER_ID: null,
        CHECK_CODE: null,
        AMOUNT: null,
        PROD_ID: `PD-${prodId}`,
      }

      const payload = {}
      params.forEach((value, key) => {
        payload[key] = key === 'AMOUNT' ? parseInt(value, 10) : value
      })

      pepayPaymentData = { ...pepayPaymentData, ...payload }
      this.submitPepayForm(pepayPaymentData)
    },
    submitPepayForm(payload) {
      const url = 'https://gate.pepay.com.tw/pepay/paysel_amt.php'
      const form = document.createElement('form')
      // Set action and target attributes for the form
      form.action = url
      form.target = '_blank'
      form.method = 'POST'
      Object.keys(payload).forEach(key => {
        const input = document.createElement('input')
        input.type = 'hidden' // Hidden input field
        input.name = key
        input.value = payload[key]
        form.appendChild(input)
      })
      document.body.appendChild(form)
      form.submit()
    },
    getPepayProdId(payment) {
      if (payment.startsWith('CONVENIENCE_STORE')) {
        return payment.replace('CONVENIENCE_STORE_', 'STORE-')
      }
      if (payment.startsWith('ATM')) {
        return payment.replace('ATM_', 'ATM-')
      }
      if (payment === 'JKO') {
        return 'EPOINT-JKOPAY'
      }
      return 'EPOINT-LINEPAY'
    },
    async onRetryPayment() {
      try {
        let data
        this.setLoading(true)
        let payload = {
          isRetry: true,
          retryTransactionID: this.$route.params.id,
        }

        payload = {
          usecase: 'RETRY_PAYMENT',
          usecase_data: {
            payment_src: this.transaction.payment_src,
            payment_src_data: {
              payment_method: this.transaction.payment_method,
              payment_method_id: this.transaction.stripe_payment_method_id,
            },
            human_id: this.$route.params.id,
          },
        }
        if (
          this.transaction.usecase === 'USER_TIER' || this.transaction.usecase === 'USER_MEMBERSHIP') {
          const res = await this.createRecurringPayment(payload, this.transaction.stripe_payment_method_id)
          data = res.data
          this.$store.commit('payment/setTransactionToCheck', null)
        } else {
          const res = await this.createPayment(payload, this.transaction.payment_id)
          data = res.data
        }

        this.encryptTransaction(data)
        this.$router.push(`/manage/payment-detail/${data.tx_human_id || data.sub_human_id || data.transactionID}`)
      } catch (error) {
        this.$store.commit('payment/setTransactionToCheck', null)
        showError(error)
      } finally {
        this.setLoading(false)
      }
    },
    setLoading(value) {
      this.$store.commit('payment/setPaymentLoading', value)
    },
    async getPaymentSnapshot(data, paymentMethodID, type) {
      try {
        if (this.paymentType) {
          const payload = await this.checkPayload(data, paymentMethodID)
          if (this.paymentType === 'gold') {
            payload.currency_as_charged = 'GANK_GOLD'
          }
          let url = 'payment/transactions/token'
          if (type && type === 'recurring') {
            url = 'payment/subscriptions/token'
          }
          const res = await this.$apiV2.post(url,
            payload)
          const result = res.data.price_data
          const currency = result.target_price_currency !== 'GANK_GOLD' ? result.target_price_currency.toLowerCase() : 'usd'
          result.processing_fees_price = result.processing_fees_price === 0 ? null : result.processing_fees_price
          this.totalDonate = formatMoney(
            result.target_price,
            formatLocaleCurrency(currency.toUpperCase()),
            currency,
          )
          this.processingFee = result.processing_fees_price ? formatMoney(
            result.processing_fees_price,
            formatLocaleCurrency(currency.toUpperCase()),
            currency,
          ) : null
          this.totalPrice = formatMoney(
            result.target_price_with_fee,
            formatLocaleCurrency(currency.toUpperCase()),
            currency,
          )
          this.isCoveredFee = res.data.is_covering_fee
          this.totalAmount = this.isCoveredFee ? result.target_price : result.target_price_with_fee
          this.currency = currency
        }
      } catch (error) {
        showError(error)
      }
    },
    async confirmStripeElement(elements, data) {
      if (!data) {
        this.setLoading(false)
      }
      const clientSecret = data?.data?.payment_provider_data.client_secret || data.clientSecret
      const { error } = await this.stripe.confirmPayment({
      // `Elements` instance that's used to create the Express Checkout Element.
        elements,
        // `clientSecret` from the created PaymentIntent
        clientSecret,
        confirmParams: {
          return_url: `${window.location.origin}/manage/payment-detail/${data.tx_human_id}`,
        },
        // Uncomment below if you only want redirect for redirect-based payments.
        // redirect: 'if_required',
      })

      if (error) {
        this.setLoading(false)
        // This point is reached only if there's an immediate error when confirming the payment. Show the error to your customer (for example, payment details incomplete).
      } else {
        // Your customer will be redirected to your `return_url`.
      }
    },
    handleElementPay(data) {
      this.$store.commit('payment/setPromptpayData', data)
    },
  },
}
